<mat-card class="btc-map-statistics"
          [ngClass]="{'btc-collapsed':this.collapsed}">

  <mat-card-header (click)="toggleExpansion($event)">
    <mat-card-title>Bitcoin Nations</mat-card-title>
    <button mat-icon-button
            (click)="toggleExpansion($event)"
            class="btc-expansion-toggle d-flex justify-content-center align-items-center">
      <mat-icon class="material-icons-outlined">expand_circle_down</mat-icon>
    </button>
  </mat-card-header>

  <mat-card-content [ngClass]="{'btc-collapsed':this.collapsed}">

    <div class="w-100 p-3 d-flex justify-content-center" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <div [ngClass]="{'d-none':dataSource.loading$ | async}">
      <btc-statistics-table [dataSource]="dataSource"
                            (nationSelected)="nationSelected.emit($event)">
      </btc-statistics-table>

      <mat-paginator [length]="nationsCount"
                     [pageSize]="defaultPageSize"
                     [pageSizeOptions]="[3, 5, 10]">
      </mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
