<mat-card class="btc-no-margin">
  <mat-card-header>
    <mat-icon mat-card-avatar>show_chart</mat-icon>
    <mat-card-title>Bitcoin Prices</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md">
        <p class="mb-0">
          We utilize the
          <a href="https://www.coingecko.com/api/documentations/v3">Coingecko API</a>
          to retrieve the current bitcoin prices for computing the current value of a nation's holdings.
        </p>
      </div>
      <div class="col-auto">
        <img src="assets/images/coingecko-logo.png"/>
      </div>
    </div>
  </mat-card-content>
</mat-card>
