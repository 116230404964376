<div *ngIf="dataSource" class="btc-statistics-table">
  <mat-menu #holdingsMenu>
    {{holdingsTooltip}}
  </mat-menu>

  <mat-tab-group [ngClass]="{'btc-tabbed':tabbed}">
    <mat-tab label="Legal Tender">
      <table mat-table
             [dataSource]="dataSource"
             width="100%">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Nation</th>
          <td mat-cell *matCellDef="let nation">{{nation.name}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Legal Tender</th>
          <td mat-cell *matCellDef="let nation">
            <div>
              <mat-icon color="primary">
                {{nation.status == 'legal' ? 'check' : 'close'}}
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sources">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sources</th>
          <td mat-cell *matCellDef="let nation">
            <div [innerHTML]="markdownService.compile(nation.sources_table)">

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name','status','sources']"></tr>
        <tr mat-row (click)="nationSelected.emit(nation)"
            *matRowDef="let nation; columns: ['name', 'status', 'sources']">
        </tr>
      </table>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <label>
          Holdings
          <mat-icon [matMenuTriggerFor]="holdingsMenu" (click)="$event.stopPropagation()">info</mat-icon>
        </label>
      </ng-template>

      <table mat-table
             [dataSource]="dataSource"
             width="100%">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Nation</th>
          <td mat-cell *matCellDef="let nation">{{nation.name}}</td>
        </ng-container>
        <ng-container matColumnDef="cost-basis">
          <th mat-header-cell *matHeaderCellDef>Cost Basis</th>
          <td mat-cell *matCellDef="let nation">
            {{costBasisDisplayString(nation)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="btc">
          <th mat-header-cell *matHeaderCellDef>BTC</th>
          <td mat-cell *matCellDef="let nation">
            {{amountDisplayString(nation)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="current-value">
          <th mat-header-cell *matHeaderCellDef>Current Value</th>
          <td mat-cell *matCellDef="let nation">
            {{currentValueDisplayString(nation)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="change">
          <th mat-header-cell *matHeaderCellDef>Change</th>
          <td mat-cell *matCellDef="let nation"
              [ngClass]="changeClass(nation)">
            <div>
              <mat-icon *ngIf="changePositive(nation)">trending_up</mat-icon>
              <mat-icon *ngIf="changeNegative(nation)">trending_down</mat-icon>
              {{changeDisplayString(nation)}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name','cost-basis','btc','current-value','change']; sticky: true"></tr>
        <tr mat-row (click)="nationSelected.emit(nation)"
            *matRowDef="let nation; columns: ['name','cost-basis','btc','current-value','change']">
        </tr>
      </table>
    </mat-tab>
  </mat-tab-group>

  <table mat-table
         [ngClass]="{'d-none':tabbed}"
         [dataSource]="dataSource"
         width="100%">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Nation</th>
      <td mat-cell *matCellDef="let nation">{{nation.name}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Legal Tender</th>
      <td mat-cell *matCellDef="let nation">
        <div>
          <mat-icon color="primary">
            {{nation.status == 'legal' ? 'check' : 'close'}}
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="cost-basis">
      <th mat-header-cell *matHeaderCellDef>Cost Basis</th>
      <td mat-cell *matCellDef="let nation">
        {{costBasisDisplayString(nation)}}
      </td>
    </ng-container>
    <ng-container matColumnDef="btc">
      <th mat-header-cell *matHeaderCellDef>BTC</th>
      <td mat-cell *matCellDef="let nation">
        {{amountDisplayString(nation)}}
      </td>
    </ng-container>
    <ng-container matColumnDef="current-value">
      <th mat-header-cell *matHeaderCellDef>Current Value</th>
      <td mat-cell *matCellDef="let nation">
        {{currentValueDisplayString(nation)}}
      </td>
    </ng-container>
    <ng-container matColumnDef="change">
      <th mat-header-cell *matHeaderCellDef>Change</th>
      <td mat-cell *matCellDef="let nation"
          [ngClass]="changeClass(nation)">
        <div>
          <mat-icon *ngIf="changePositive(nation)">trending_up</mat-icon>
          <mat-icon *ngIf="changeNegative(nation)">trending_down</mat-icon>
          {{changeDisplayString(nation)}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="sources">
      <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sources</th>
      <td mat-cell *matCellDef="let nation" (click)="sourcesClicked($event)">
        <markdown ngPreserveWhitespaces>
          {{nation.sources_table}}
        </markdown>
      </td>
    </ng-container>

    <ng-container matColumnDef="header-holdings">
      <th mat-header-cell *matHeaderCellDef
          [attr.colspan]="4">
        <label class="justify-content-center">
          Holdings
          <mat-icon [matMenuTriggerFor]="holdingsMenu">info</mat-icon>
        </label>
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['name','status','header-holdings','sources']"></tr>
    <tr mat-header-row *matHeaderRowDef="['cost-basis','btc','current-value','change']; sticky: true"></tr>
    <tr mat-row (click)="nationSelected.emit(nation)"
        *matRowDef="let nation; columns: ['name', 'status', 'cost-basis', 'btc', 'current-value', 'change', 'sources']">
    </tr>
  </table>
</div>
