<mat-card matTooltip="Data provided by CoinGecko" matTooltipPosition="below">
  <mat-card-content>
    <div class="row align-items-center">
      <div class="col-auto pe-0">
        <img src="assets/images/bitcoin.png" [width]="16" height="auto"/>
      </div>
      <div class="col">
        {{currentBitcoinPriceFormatted}}
      </div>
    </div>
  </mat-card-content>
</mat-card>
