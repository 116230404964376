<nav class="navbar navbar-expand-lg fixed-top px-2"
     [ngClass]="{'bg-dark':configService.darkmode,'bg-light':!configService.darkmode}">

  <a class="navbar-brand"
     [ngClass]="{'text-light':configService.darkmode,'text-dark':!configService.darkmode}"
     routerLink="/map">
    <img [height]="28" [width]="28" src="../../assets/images/bitcoin.png"/>itcoinations
  </a>

  <div class="flex-grow-1 d-flex justify-content-end pe-2">
    <button class="btc-theme-toggle text-start ms-3 d-flex justify-content-center"
            mat-icon-button
            [ngClass]="{'btc-lightmode-button':configService.darkmode,'btc-darkmode-button':!configService.darkmode}"
            (click)="configService.darkmode=!configService.darkmode"
            [matTooltip]="configService.darkmode ? 'Enable Light Mode' : 'Enable Dark Mode'">
      <mat-icon class="material-icons-outlined m-0">
        {{configService.darkmode ? 'light_mode' : 'dark_mode'}}
      </mat-icon>
    </button>
  </div>

  <button #navbarToggleButton
          mat-icon-button
          class="navbar-toggler collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          (click)="collapsed = !collapsed"
          [attr.aria-expanded]="!collapsed">
    <span class="icon-bar top-bar"></span>
    <span class="icon-bar middle-bar"></span>
    <span class="icon-bar bottom-bar"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end flex-grow-0" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <a *ngFor="let navigationEntry of navigationEntries"
         class="nav-item p-2 text-start ms-0 ms-md-2 mt-2 mt-md-0"
         mat-flat-button
         [ngClass]="{'btc-active':isActivated(navigationEntry)}"
         [routerLink]="navigationEntry.path">
        <mat-icon class="material-icons-outlined">{{navigationEntry.icon}}</mat-icon>
        {{navigationEntry.name}}
      </a>
    </ul>
  </div>

</nav>
