<div class="container py-4">
  <div class="row">
    <div class="col-12">
      <btc-description></btc-description>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 mt-3">
      <btc-privacy></btc-privacy>
      <div class="mt-3">
        <btc-prices></btc-prices>
      </div>
      <div class="mt-3">
        <btc-world-map></btc-world-map>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <btc-donations></btc-donations>
    </div>
  </div>
</div>
