<mat-card class="btc-no-margin h-100">
  <mat-card-header>
    <mat-icon mat-card-avatar>volunteer_activism</mat-icon>
    <mat-card-title>Donate Bitcoin?</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      We develop this page in our free time.
      Donations could increase the motivation to spend even more of it into implementing more features.
      <mat-icon class="material-icons-outlined" inline>sentiment_very_satisfied</mat-icon>
    </p>
    <p>
      Anyhow, if you (independently of any donations!) got ideas for fancy features for this page, please
      <a href="mailto:bitcoinations@protonmail.com">mail us</a>
      or create an issue on <a href="https://github.com/Hakenadu/bitcoinations">GitHub</a>.
    </p>
    <div class="row mt-3 justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7">
        <div class="w-100 text-center">
          <h2>BTC Address</h2>
          <img src="assets/images/donations-bitcoin.png"
               class="h-auto"
               [cdkCopyToClipboard]="donateBitcoin"
               (click)="showClipboardCopyText('BTC Address')"/>
          <div class="d-flex flex-row align-items-center justify-content-center pt-3">
            <span class="wallet-address pr-2">{{donateBitcoin}}</span>
            <button [cdkCopyToClipboard]="donateBitcoin"
                    mat-icon-button
                    class="text-center"
                    (click)="showClipboardCopyText('BTC Address')">
              <mat-icon class="material-icons-outlined">content_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-7 mt-3">
        <div class="w-100 text-center">
          <h2>Liquid Address</h2>
          <img src="assets/images/donations-liquid.png"
               class="h-auto"
               [cdkCopyToClipboard]="donateLiquid"
               (click)="showClipboardCopyText('Liquid Address')"/>
          <div class="d-flex flex-row align-items-center justify-content-center pt-3">
            <span class="wallet-address pr-2">{{donateLiquid}}</span>
            <button [cdkCopyToClipboard]="donateLiquid"
                    mat-icon-button
                    class="text-center"
                    (click)="showClipboardCopyText('Liquid Address')">
              <mat-icon class="material-icons-outlined">content_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
