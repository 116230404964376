<div class="container py-4">
  <mat-card class="btc-no-margin">
    <mat-card-header>
      <mat-icon mat-card-avatar class="material-icons-outlined">description</mat-icon>
      <mat-card-title><h2>BITCOINATIONS TERMS OF SERVICE</h2></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        Bitcoinations.net (the "Website") is an Internet service owned and operated by two bored developers.
        Bitcoinations is dedicated to providing visitors to the Website with a highly interactive and
        positive experience, while at the same time protecting our rights and the rights of our users. We have developed
        these Terms and Conditions to govern your use of the Website. We, along with our affiliates, partners and
        advertisers, provide content and services to you via the Website subject to the following conditions. By using
        the Website, you agree and consent to the policies contained in the below Terms and Conditions.
      </p>
      <h3>1. The Website Content</h3>


      <h4>1.1</h4>


      <p>
        All of the information, content, services and software displayed on, transmitted through, or used in connection
        with the Website, including for example, advertising, directories, guides, articles, opinions, reviews, text,
        photographs, images, illustrations, audio clips, video, html, source and object code, software, data, and all
        other related matters, including without limitation, the selection and arrangement of the aforementioned and the
        "look and feel" of this website (collectively, the "Content"), are protected under applicable copyrights and
        other proprietary (including but not limited to intellectual property) rights and are the intellectual property
        of Bitcoinations, and its affiliated companies, licensors and suppliers. Bitcoinations actively protects its
        rights to the Content to the fullest extent of the law. The copying, rearrangement, broadcast, rewriting for
        broadcast or publication, redistribution, modification, use or publication by you in any medium, directly or
        indirectly, of any such matters or any part of the Website, including the removal or alteration of advertising,
        except for limited rights of use granted hereunder, is strictly prohibited. You may not use such material except
        as provided in these Terms and Conditions.
      </p>
      <h4>1.2</h4>


      <p>
        The Content includes logotypes, trademarks and service marks (collectively "Marks") owned by Bitcoinations, and
        Marks owned by other information providers and third parties. No Marks may be used in any manner unless approved
        in advance, in writing by Bitcoinations.
      </p>
      <h4>1.3</h4>


      <p>
        You may use the Content online and solely for your personal, non-commercial use, and you may download or print a
        single copy of any portion of the Content for your personal, non-commercial use, provided you do not remove any
        trademark, copyright or other notice contained in such Content. No other use is permitted unless otherwise
        expressly permitted by Bitcoinations. You may not, for example, republish the Content on any Internet, Intranet
        or Extranet site or incorporate the Content in any database, compilation, archive or cache or store the Content
        in electronic form on your computer or mobile device unless otherwise expressly permitted by Bitcoinations. You
        may not distribute any of the Content to others, whether or not for payment or other consideration, and you may
        not modify, copy, frame, reproduce, sell, publish, transmit, display or otherwise use any portion of the Content
        without securing the prior written consent of Bitcoinations. Any article reviews, forum posts or other
        information posted by you on the Website become exclusive property of Bitcoinations.
      </p>
      <h4>1.4</h4>


      <p>
        The Website is not a financial reference resource and the opinions of authors and other contributors are their
        own and should not be taken as financial advice. All materials on this site are for informational purposes only.
        None of the material should be interpreted as investment advice.
      </p>
      <h3>2. Disclaimer</h3>


      <p>
        <strong>ALL MATERIALS AND SERVICES ON THE WEBSITE AND ANY THIRD-PARTY SITES TO WHICH THE FOREGOING LINKS ARE
          PROVIDED "AS IS" OR "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND. BITCOINATIONS IS NOT RESPONSIBLE FOR THE
          AVAILABILITY OR CONTENT OF OTHER SERVICES THAT MAY BE LINKED TO THE WEBSITE. BECAUSE BITCOINATIONS HAS NO
          CONTROL OVER SUCH SERVICES, YOU ACKNOWLEDGE AND AGREE THAT BITCOINATIONS IS NOT RESPONSIBLE FOR THE
          AVAILABILITY OF SUCH EXTERNAL SERVICES, AND THAT BITCOINATIONS DOES NOT ENDORSE AND IS NOT RESPONSIBLE OR
          LIABLE FOR ANY CONTENT, ACCURACY, QUALITY, ADVERTISING, PRODUCTS OR OTHER MATERIALS ON OR AVAILABLE FROM SUCH
          EXTERNAL SERVICES. YOU FURTHER ACKNOWLEDGE AND AGREE THAT BITCOINATIONS SHALL NOT BE RESPONSIBLE OR LIABLE,
          DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH THE USE
          OF OR RELIANCE ON ANY CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH SUCH EXTERNAL SERVICES.THE CONTENT
          PUBLISHED ON THE WEBSITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY MADE TO
          THE INFORMATION ON THE WEBSITE. Bitcoinations AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES TO THE
          WEBSITE AT ANY TIME INCLUDING CHANGES TO THESE TERMS AND CONDITIONS. THE WEBSITE OFFERS A SEARCH FEATURE
          WITHIN THE SITE. BITCOINATIONS EXPLICITLY DISCLAIMS ANY RESPONSIBILITY FOR THE CONTENT OR AVAILABILITY OF
          INFORMATION CONTAINED IN OUR SEARCH INDEX OR DIRECTORY. BITCOINATIONS ALSO DISCLAIMS ANY RESPONSIBILITY FOR
          THE COMPLETENESS OR ACCURACY OF ANY DIRECTORY OR SEARCH RESULT. BITCOINATIONS AND/OR ITS SUPPLIERS MAKE NO
          REPRESENTATIONS AND, TO THE FULLEST EXTENT ALLOWED BY LAW, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE REGARDING THE
          SUITABILITY OF THE INFORMATION; THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE CONTENT,
          SERVICES, PRODUCTS, TEXT, GRAPHICS, LINKS, OR OTHER ITEMS CONTAINED WITHIN THE WEBSITE, OR THE RESULTS
          OBTAINED FROM ACCESSING AND USING THE WEBSITE AND/OR THE CONTENT CONTAINED THEREIN. BITCOINATIONS DOES NOT
          WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL
          BE CORRECTED, OR THAT THE WEBSITE, INCLUDING BULLETIN BOARDS OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE
          OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE USER ASSUMES THE ENTIRE COST OF ALL NECESSARY MAINTENANCE, REPAIR
          OR CORRECTION OF THE USERS COMPUTER AND OTHER EQUIPMENT. BCASH IS NOT BITCOIN.</strong>
      </p>
      <h3>3. Indemnity/Limitation of Liability</h3>


      <p>
        AS A CONDITION OF USE OF THIS WEBSITE, YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS, BITCOINATIONS AND ITS
        SUPPLIERS, EACH OF THEIR PARENT AND AFFILIATED COMPANIES, AND EACH OF THEIR RESPECTIVE PARTNERS, SUPPLIERS,
        LICENSORS, OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, EMPLOYEES, REPRESENTATIVES, CONTRACTORS AND AGENTS, FROM
        ANY AND ALL CLAIMS (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR DEFAMATION, TRADE DISPARAGEMENT, PRIVACY AND
        INTELLECTUAL PROPERTY INFRINGEMENT) AND DAMAGES (INCLUDING ATTORNEYS' FEES AND COURT COSTS) ARISING FROM OR
        RELATING TO ANY ALLEGATION REGARDING: (A) YOUR USE OF THE WEBSITE; (B) Bitcoinations' USE OF ANY CONTENT OR
        INFORMATION YOU PROVIDE, AS LONG AS OUR USE IS NOT INCONSISTENT WITH THIS AGREEMENT; (C) INFORMATION OR MATERIAL
        POSTED OR TRANSMITTED THROUGH YOUR MEMBERSHIP ACCOUNT, EVEN IF NOT POSTED BY YOU; AND, (D) ANY VIOLATION OF
        THESE TERMS AND CONDITIONS BY YOU. If you are dissatisfied with any Bitcoinations material, or with any of
        Bitcoinations' terms and conditions, your sole and exclusive remedy is to discontinue using the Website, as
        applicable.
      </p>
      <p>
        Under no circumstances, including, but not limited to, negligence, shall Bitcoinations, or its affiliates be
        liable for any direct, indirect, incidental, special or consequential damages that result from the use of, or
        the inability to use, the Content, unauthorized access to or alteration of your transmissions or data, the
        Content or any errors or omissions in the Content, even if advised of the possibility of such damages. You
        specifically acknowledge and agree that Bitcoinations is not liable for any conduct of any user.
      </p>
      <h3>5. General</h3>


      <p>
        These Terms and Conditions shall be governed by the law of Germany. You consent to submit to the exclusive
        jurisdiction of the courts of Germany.
      </p>
      <h3>6. Service Contact</h3>


      <p>
        Contact <a href="mailto:bitcoinations@protonmail.com">bitcoinations@protonmail.com</a> with questions or
        problems with the Website.
      </p>
    </mat-card-content>
  </mat-card>
</div>
