<ng-container *ngIf="nation">
  <h1 mat-dialog-title>{{nation.name}}</h1>
  <mat-dialog-content>

    <h2 mat-dialog-title> Legal Tender </h2>

    <mat-icon color="primary">
      {{nation.status === 'legal' ? 'check' : 'close'}}
    </mat-icon>

    <ng-container>
      <h2 mat-dialog-title>Holdings</h2>

      <div *ngIf="!nation.holdings || nation.holdings.length===0">
        N/A
      </div>

      <table mat-table *ngIf="nation.holdings && nation.holdings.length>0"
             [dataSource]="nation.holdings"
             class="btc-popup-table" width="100%">

        <ng-container matColumnDef="cost_basis">
          <th mat-header-cell *matHeaderCellDef>Cost Basis</th>
          <td mat-cell *matCellDef="let holding">
            {{costBasisDisplayString(holding)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>BTC</th>
          <td mat-cell *matCellDef="let holding">
            {{amountDisplayString(holding)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="current-value">
          <th mat-header-cell *matHeaderCellDef>Current Value</th>
          <td mat-cell *matCellDef="let holding">{{currentValueDisplayString(holding)}}</td>
        </ng-container>

        <ng-container matColumnDef="change">
          <th mat-header-cell *matHeaderCellDef>Change</th>
          <td mat-cell *matCellDef="let holding">
            <mat-icon *ngIf="changePositive(holding)">trending_up</mat-icon>
            <mat-icon *ngIf="changeNegative(holding)">trending_down</mat-icon>
            {{changeDisplayString(holding)}}
          </td>
        </ng-container>


        <!--
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef> Current Value </th>
          <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
        </ng-container>


        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Change % </th>
          <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
        </ng-container>
        -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </ng-container>

    <ng-container *ngIf="nation.sources_popup">
      <h2 mat-dialog-title>Sources</h2>
      <div [innerHTML]="markdownService.compile(nation.sources_popup)">

      </div>
    </ng-container>

  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button
            (click)="matDialogRef.close()">
      Close
    </button>
  </mat-dialog-actions>
</ng-container>
