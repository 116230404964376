<mat-card class="btc-no-margin">
  <mat-card-header>
    <mat-icon mat-card-avatar>public</mat-icon>
    <mat-card-title>World Map</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md">
        <p class="mb-0">
          We created the interactive map on the <a routerLink="/map">main page</a> by using
          <a href="https://www.amcharts.com/">amCharts</a>.
        </p>
      </div>
      <div class="col-auto">
        <img [src]="logoSource" width="200px" height="auto"/>
      </div>
    </div>
  </mat-card-content>
</mat-card>
