<div class="container py-4">
  <mat-card class="btc-no-margin">
    <mat-card-header>
      <mat-icon mat-card-avatar class="material-icons-outlined">privacy_tip</mat-icon>
      <mat-card-title><h2>BITCOINATIONS PRIVACY POLICY</h2></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of
        Personal Information if anyone decided to use our Service.
      </p>
      <p>
        If you choose to use our Service, then you agree to the collection and use of information in relation to this
        policy.
        The Personal Information that we collect is used for providing and improving the Service. We will not use or
        share
        your information with anyone except as described in this Privacy Policy.
      </p>
      <h3>Information Collection and Use</h3>


      <p>
        For a better experience, we may require you to provide us with certain personally identifiable information,
        including
        but not limited to your name, phone number, and postal address. The information that we collect will be used to
        contact or identify you.
      </p>
      <h3>Log Data</h3>


      <p>
        <strong>Whenever you visit our Service, we collect information that your browser sends to us that is called Log
          Data.
          This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, location
          derived
          from IP address, browser version, OS, browser language, pages of our Service that you visit, the time and date
          of
          your visit, the time spent on those pages, referring URL, and other statistics.</strong>
      </p>
      <h3>Cookies</h3>


      <p>
        Cookies are files with small amounts of data that are commonly used as an anonymous unique identifier. These are
        sent
        to your browser from the website that you visit and stored on your computer’s hard drive
      </p>
      <ul>

        <li>Providing you with tailored content and advertisements (see below for more information);

        <li>Enabling social media features;

        <li>Safeguarding against spam and malware;

        <li>Analyzing trends, traffic, and user behavior;

        <li>Administering our Service;

        <li>Gathering demographic information about our user base as a whole;

        <li>Tracking web and advertising analytics throughout our Service;

        <li>Remembering your preferences and voluntarily-submitted information;

        <li>Performing location-related functionalities and analytics;

        <li>Participating in market research (e.g., site ratings); and

        <li>Educating ourselves about how we can continue to improve our Service and its various elements.
        </li>
      </ul>
      <p>
        Our website uses these “cookies” to ensure certain website features function properly. Cookies do not allow us
        to
        gather any personal information about you and we do not generally store any personal information that you
        provided
        to
        us in your cookies. You are free to delete these cookies from within your web browser.
      </p>
      <p>
        You agree to the use of these cookies by visiting our website and using its features.
      </p>
      <h3>Service Providers</h3>


      <p>
        We may employ third-party companies and individuals due to the following reasons
      </p>
      <ul>

        <li>To facilitate our Service;

        <li>To provide the Service on our behalf;

        <li>To perform Service-related services; or

        <li>To assist us in analyzing how our Service is used.
        </li>
      </ul>
      <p>
        We want to inform our Service users that these third parties have access to your Personal Information and
        information
        in your Cookies. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated
        not to
        disclose or use the information for any other purpose.
      </p>
      <h3>Legal Reasons</h3>


      <p>
        We will share Personal Information if we have a good-faith belief that access, use, preservation, or disclosure
        of
        the
        information is reasonably necessary to:
      </p>
      <ul>

        <li>Meet any applicable law, regulation, legal process, or enforceable governmental request.

        <li>Enforce our Terms of Service, including investigation of potential violations.

        <li>Detect, prevent, or otherwise address fraud, security, or technical issues.

        <li>Protect against harm to the rights, property or safety of us, our users, or the public as required or
          permitted by
          law.
        </li>
      </ul>
      <h3>Security</h3>


      <p>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially
        acceptable
        means of protecting it. But remember that no method of transmission over the internet, or method of electronic
        storage
        is 100% secure and reliable, and we cannot guarantee its absolute security.
      </p>
      <h3>Links to Other Sites</h3>


      <p>
        Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that
        site.
        Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy
        Policy
        of these websites. We have no control over and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>
      <h3>Children’s Privacy</h3>


      <p>
        We do not knowingly collect or solicit personal information from anyone under the age of 13. In the event that
        we
        learn that we have collected personal information from a child under age 13, we will delete that information as
        quickly as possible. If you believe that we might have any information from or about a child under age 13,
        please
        contact us at <a href="mailto:bitcoinations@protonmail.com">bitcoinations@protonmail.com</a>.
      </p>
      <h3>Changes to This Privacy Policy</h3>


      <p>
        We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any
        changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are
        effective
        immediately after they are posted on this page.
      </p>
      <h3>Data Retention</h3>


      <p>
        We will retain your information for as long as is needed to provide our Service, or as required to fulfill our
        legal
        obligations. We will retain and use your information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements.
      </p>
      <p>
        By contacting us at <a href="mailto:bitcoinations@protonmail.com">bitcoinations@protonmail.com</a> you can at
        any
        time
        obtain confirmation of whether or not personal data concerning you is being processed.
      </p>
      <p>
        You have the right to access and export your information at any time by sending a request to <a
        href="mailto:bitcoinations@protonmail.com">bitcoinations@protonmail.com</a>.
      </p>
      <h3>Contact Us</h3>


      <p>
        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
        href="mailto:bitcoinations@protonmail.com">bitcoinations@protonmail.com</a>.
      </p>
    </mat-card-content>
  </mat-card>
</div>
