import {Component} from '@angular/core';

@Component({
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent {

  constructor() {
  }
}
